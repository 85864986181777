import styled from 'styled-components';

export const StyledTopo = styled.div`
    display: flex;
    position: fixed;
    z-index: 4;
    width: 100%;
    justify-content: space-between;
    background-color: #fff; /* Fundo branco para o topo */
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1); /* Sombra sutil */
    opacity: 0; /* Inicialmente invisível */
    transition: opacity 1s ease; /* Transição suave */

    ul {
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        li {
            margin-right: 10px;
            padding-left: 10px;

            img {
                max-width: 25px; /* Tamanho dos ícones */
                height: auto; /* Mantém a proporção */
            }
        }
    }

    @media screen and (max-width: 768px) {
        ul {
            align-items: flex-start;
        }
        a {
            font-size: 12px; /* Ajusta o tamanho da fonte */
        }
    }

    &.visible {
        opacity: 1; /* Torna o topo visível */
    }
`;

export const StyledApresentacao = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: linear-gradient(135deg, #FFF700, #FFE135, #FFD700, #FFC300);
    position: relative;
    opacity: 0;
    transition: opacity 1s ease;
    top: 10px;

    .texto-container {
        position: absolute;
         z-index: 10; /* Certifique-se de que está acima do vídeo */
         bottom: 0;
       
        display: flex;
      
        align-items: center;
    }

    button {
        
        background-color: white;
        border: none;
        border-radius: 30px;
        cursor: pointer;
        transition: background-color 0.3s;
        font-size: 20px;
        margin-top: 10px;  

        &:hover {
            background-color: #FFE004;
        }
    }

    &.visible {
        opacity: 1;
    }

    @media screen and (max-width: 700px) {
        button {
            font-size: 18px;
        }
    }

    @media screen and (max-width: 420px) {
        height: 100vh;

        button {
            font-size: 16px;
        }
    }
`;
