import imagem1 from '../public/imagem/estatuaDireito.jpg';
import imagemWhts from '../public/imagem/whatsapp.png';

import imagemfacebook from '../public/imagem/facebook.svg';
import imagemInsta from '../public/imagem/instagram.svg';
import imagemLinkedin from '../public/imagem/linkedin.svg';
import imagemYoutube from '../public/imagem/youtube.svg';

import imagemTributario from '../public/imagem/tributario.png'
import imagemtrabalhista from '../public/imagem/trabalhista.png'
import imagemcivil from '../public/imagem/civil.png'
import imagemempresarial from '../public/imagem/empresarial.png'
import imagemequipe from '../public/imagem/equipe.png'
import imagemfamilia from '../public/imagem/familia.png'

import adv1foto from '../public/imagem/adv1.jpg'
import adv2foto from '../public/imagem/adv2.jpg'
import adv3foto from '../public/imagem/adv3.jpg'
import adv4foto from '../public/imagem/adv4.jpg'

import fotoadv from '../public/imagem/fotoAdvogada.jpg'

export const data = {
    style: {
        corSpanTimeDeESpecialista:'#696969'
    },

    topoMenu: {
        imagemFundo: imagem1,
        whatsappimg: imagemWhts,
        imagemInsta: imagemInsta,
        linkInstagran: 'https://www.instagram.com/',
        imagemfacebook: imagemfacebook,
        linkfacebook: 'https://www.facebook.com/',
        imagemLinkedin: imagemLinkedin,
        linkLinkedin: 'https://www.linkedin.com/feed/',
        imagemYoutube: imagemYoutube,
        linkYoutube: 'https://www.youtube.com/',
        whatsapp: '1199873-1174',
        telefone: '1199873-1174',
        email: 'professoredson@gmail.com',
        nome: ' ',
        texto: "Candidato a vereador - Compromisso com a comunidade",
        botao: "Conheça minhas propostas",
        corFundoOpcoesTopo: "rgba(255, 255, 255, 0.4)",
        corTexto: "#fff",
        corBotaoFundo: "rgba(255, 255, 255, 0.4)",
        corBotaoHover: "#666"
    },

    sobreNos: {
        fotoAdv: fotoadv,
        sobrenos: '- SOBRE',
        titulo: 'Conheça o Professor Edson - Candidato a Vereador',
        paragrafo1: 'Professor Edson é um líder comunitário com anos de experiência em educação e gestão esportiva. Como dono de uma academia, ele conhece bem os desafios enfrentados pela nossa comunidade.',
        paragrafo2: 'Com uma visão voltada para o futuro, Professor Edson busca melhorias na educação, no esporte e na qualidade de vida para todos os cidadãos.',
        paragrafo3: 'Sua candidatura a vereador representa um compromisso com a transparência, a justiça e o desenvolvimento sustentável para nossa cidade.',
        corFundo: "rgba(248, 247, 77,0.5)", 
    },

    servicos: {
        servico: 'PROPOSTAS',
        titulo: '',
        texto: 'Conheça mais sobre as principais propostas do Professor Edson para nossa cidade',

        tributaria: {
            imagemTributaria: imagemTributario,
            tributaria: 'Educação',
            texto: 'Melhoria das infraestruturas escolares, valorização dos professores e implementação de programas de apoio aos estudantes.',
        },
        empresarial: {
            imagemempresarial: imagemempresarial,
            empresarial: 'Esporte e Lazer',
            texto: 'Criação e manutenção de espaços públicos para a prática de esportes e atividades físicas, incentivando um estilo de vida saudável.',
        },
        administrativo: {
            imagemadministrativo: imagemequipe,
            administrativo: 'Segurança',
            texto: 'Parcerias com as forças de segurança para garantir a proteção da comunidade e reduzir a criminalidade.',
        },
        civil: {
            imagemTributaria: imagemcivil,
            civil: 'Saúde',
            texto: 'Aprimoramento dos serviços de saúde, com foco na prevenção e no atendimento de qualidade para todos.',
        },
        famíliaESucessões: {
            imagemTributaria: imagemfamilia,
            famíliaESucessões: 'Infraestrutura',
            texto: 'Investimento em infraestrutura urbana, incluindo pavimentação, iluminação e saneamento básico.',
        },
        trabalhista: {
            imagemtrabalhista: imagemtrabalhista,
            trabalhista: 'Economia',
            texto: 'Fomento ao empreendedorismo local e criação de oportunidades de emprego para jovens e adultos.',
        },
    },

    profissionais: {
        texto1: 'EQUIPE DE APOIO',
        titulo: 'Conheça nossa equipe',
        texto: 'Uma equipe dedicada e comprometida com as propostas do Professor Edson.',

        profissional1: {
            foto: adv1foto,
            nome: 'Ana Teixeira Dante',
            especialidade: 'Coordenadora de Campanha',
            texto: 'Ana tem vasta experiência em coordenação de campanhas políticas e é uma estrategista habilidosa.',
            telefone: '9999999',
            email: 'ana.teixeira@email.com',
            facebook: 'https://facebook.com/anateixeira',
            youtube: 'https://youtube.com/anateixeira',
            linkedin: 'https://linkedin.com/in/anateixeira',
            instagran: 'https://instagram.com/anateixeira'
        },
        profissional2: {
            foto: adv2foto,
            nome: 'Guilhermina Ramires',
            especialidade: 'Consultora de Políticas Públicas',
            texto: 'Guilhermina é especialista em políticas públicas com foco em educação e saúde.',
            telefone: '99873-1174',
            email: 'guilhermina.ramires@email.com',
            facebook: 'https://facebook.com/guilherminaramires',
            youtube: 'https://youtube.com/guilherminaramires',
            linkedin: 'https://linkedin.com/in/guilherminaramires',
            instagran: 'https://instagram.com/guilherminaramires'
        },
        profissional3: {
            foto: adv3foto,
            nome: 'João Dark Santos',
            especialidade: 'Analista de Comunicação',
            texto: 'João é responsável pela comunicação da campanha, garantindo uma mensagem clara e eficaz.',
            telefone: '99873-1174',
            email: 'joao.santos@email.com',
            facebook: 'https://facebook.com/joaosantos',
            youtube: 'https://youtube.com/joaosantos',
            linkedin: 'https://linkedin.com/in/joaosantos',
            instagran: 'https://instagram.com/joaosantos'
        },
        profissional4: {
            foto: adv4foto,
            nome: 'Carlos Daqmasco',
            especialidade: 'Gestor de Projetos',
            texto: 'Carlos é responsável pela gestão de projetos da campanha, garantindo a execução eficiente das propostas.',
            telefone: '99873-1174',
            email: 'carlos.daqmasco@email.com',
            facebook: 'https://www.facebook.com/edson.constantino1',
            youtube: 'https://youtube.com/carlosdaqmasco',
            linkedin: 'https://linkedin.com/in/carlosdaqmasco',
            instagran: 'https://www.instagram.com/edson_constantino/following/'
        },
    },

    contatos: {
        faleConosco: 'DEIXE SEU CONTATO',
        titulo: 'Enviaremos novas informações',
        texto: 'Encontre-nos também nas redes sociais:',
    },

    local: {
        local: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3654.0061662066573!2d-46.352595424666404!3d-23.675737478721445!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce73cb1cbcda9b%3A0xa633939c10e71072!2sAlpha%20Sports%20Ouro%20Fino!5e0!3m2!1spt-BR!2sbr!4v1721135598124!5m2!1spt-BR!2sbr" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade'
    },

    fotter: {
        titulo1: 'Professor Edson',
        titulo3: 'Áreas de Propostas',
        texto: 'Professor Edson é candidato a vereador com propostas nas áreas de Educação, Esporte e Lazer, Segurança, Saúde, Infraestrutura e Economia.',
        
        titulo2: 'Visite-nos',
        semana: '✔ Seg-Sex 06h às 22h. | Sábados 09h às 12h',
        endereco: '🗺️  Rod. Índio Tibiriçá, 2565 - Centro Ouro Fino Paulista, Ribeirão Pires',
        linkEndereço: 'https://maps.app.goo.gl/gAQckXG1LmU6HqKy6',
        //telefone: '☎ (11) 44444-44444',
        celular: '📱 (11) 99873-1174',
        //email: '📧 professoredson@gmail.com',

        areas: 'ÁREAS DE PROPOSTAS',
        texto1: 'Educação',
        texto2: 'Esporte e Lazer',
        texto3: 'Segurança',
        texto4: 'Saúde',
        texto5: 'Infraestrutura',
        texto6: 'Economia',

        Copyrigh: '© Copyright Professor Edson - Todos os direitos reservados'
    }
};