import styled from 'styled-components'
import candidatura from './candidatura.jpeg'

const StyleFoto = styled.section`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f0f0f0; /* Cor de fundo opcional */

    img {
        max-width: 50%;
        height: auto; /* Mantém a proporção da imagem */

        @media (max-width: 500px) {
            max-width: 100%;
        }
    }
`

export default function CandidaturaFoto() {
    return (
        <StyleFoto>
            <img src={candidatura} alt="Candidatura" />
        </StyleFoto>
    )
}
