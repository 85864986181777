import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;

  /* Estilo para os pontos de navegação */
  .slick-dots li button:before {
    color: black; /* Cor dos pontos de navegação */
  }

  @media (max-width: 700px) {
    .slick-dots li button:before {
      display: none;
    }
  }

  .slick-prev,
  .slick-next {
    width: 50px; /* Tamanho das setas */
    height: 50px; /* Tamanho das setas */
    top: 50%; /* Posição vertical das setas */
    transform: translateY(-50%); /* Centraliza verticalmente */
    background-color: transparent; /* Fundo transparente */
    border: none; /* Remove a borda padrão */
    z-index: 1; /* Garante que as setas fiquem sobre os slides */
  }

  .slick-prev:before,
  .slick-next:before {
    font-size: 30px; /* Tamanho dos ícones das setas */
    color: black; /* Cor das setas */
  }

  .slick-prev {
    left: 10px; /* Posição horizontal da seta anterior */  
  }

  .slick-next {
    right: 10px; /* Posição horizontal da seta próxima */
  }
`;

export const Title = styled.h1`
  text-align: center;
  margin: 24px 0;
`;

export const SlideItem = styled.div`
  position: relative;
  width: 100%;
  height: 700px; /* Ajuste conforme necessário */
  padding: 10px; /* Ajuste o padding conforme necessário */

  img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Faz com que a imagem cubra o contêiner mantendo a proporção */
    display: block;
  }
`;
