import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import video1 from './videoEdson1.mp4';
import video2 from './videoedson2.mp4';
import video3 from './videoedson3.mp4';
import video4 from './vidio4.mp4';

const Container = styled.section`
  text-align: center;
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const VideoContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px; 
  justify-content: center; 
  padding-top: 30px;
`;

const Video = styled.video`
  max-width: 300px; 
  width: 100%;
  height: 300px;
  object-fit: cover;
  padding: 20px;
`;

const videos = [
  { src: video1 },
  { src: video2 },
  { src: video3 },
  { src: video4 },
];

export default function Carrocelvidio() {
  const videoRefs = useRef([]);

  useEffect(() => {
    videoRefs.current.forEach((video) => {
      if (video) {
        video.play().catch((error) => {
          console.error('Erro ao iniciar o vídeo:', error);
        });
      }
    });

    // Clean up on unmount
    return () => {
      videoRefs.current.forEach((video) => {
        if (video) {
          video.pause(); // Pause videos when component unmounts
        }
      });
    };
  }, []);

  return (
    <Container>
      <h1>Vídeos</h1>
      <VideoContainer>
        {videos.map((video, index) => (
          <Video
            key={index}
            controls
            ref={(el) => (videoRefs.current[index] = el)}
            preload="metadata"
            autoPlay
            muted
          >
            <source src={video.src} type="video/mp4" />
            Seu navegador não suporta a tag de vídeo.
          </Video>
        ))}
      </VideoContainer>
    </Container>
  );
}
