import React from 'react';
import styled from 'styled-components';
import img from './numeroEdson.jpeg';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
background-color: #FBF898;
  border-radius: 15px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  max-width: 1000px;
  margin: auto;
  margin-top: 10px;
 

  @media (min-width: 768px) {
    flex-direction: row;
    padding: 40px;
  }
`;

const Image = styled.img`
  width: 100%;
  max-width: 350px;
  height: auto;
  border-radius: 15px;
  margin-bottom: 20px;

  @media (min-width: 768px) {
    margin-right: 30px;
    margin-bottom: 0;
  }
`;

const TextContainer = styled.div`
  text-align: center;

  @media (min-width: 768px) {
    text-align: left;
    max-width: 600px;
  }
`;

const Title = styled.h2`
  font-size: 28px;
  color: #222;
  margin-bottom: 15px;
`;

const Subtitle = styled.h3`
  font-size: 20px;
  color: #444;
  margin-bottom: 20px;
`;

const Description = styled.p`
  font-size: 18px;
  color: black;
  line-height: 1.8;
  margin-bottom: 20px;
`;

const StrongText = styled.strong`
  color: #000;
  font-weight: bold;
`;

const Eleicao = () => {
  return (
    <Container>
      <Image src={img} alt="Prof Edson Constantino" />
      <TextContainer>
        <Title>Sobre o Professor Edson Constantino</Title>
        <Subtitle>Vereador - Ribeirão Pires/SP | Partido Socialista Brasileiro - PSB</Subtitle>
        <Description>
          O Professor Edson Constantino, candidato a vereador por Ribeirão Pires/SP, é um educador dedicado
          com uma longa trajetória de serviço à comunidade. Representando o Partido Socialista Brasileiro (PSB),
          ele está comprometido em trazer mudanças significativas para a cidade, focando em educação, saúde,
          e melhorias na infraestrutura local. Com o número <StrongText>40740</StrongText>, Edson busca continuar sua missão de
          transformar Ribeirão Pires em um lugar melhor para todos, promovendo políticas que favorecem a igualdade,
          a justiça social e o desenvolvimento sustentável.
        </Description>
      </TextContainer>
    </Container>
  );
};

export default Eleicao;
