import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Container, SlideItem, Title } from './carrocel';
import imagem1 from './imagem1.jpg';
import imagem2 from './imagem2.jpg';
import imagem3 from './imagem3.jpg';
import imagem4 from './imagem4.jpg';
import foto1 from './foto1.jpeg';
import foto2 from './foto2.jpeg';
import foto3 from './foto3.jpeg';
import foto4 from './foto4.jpeg';
import foto5 from './foto5.jpeg';
import foto6 from './foto6.jpeg';
import foto8 from './foto8.jpeg';
import foto10 from './foto10.jpeg';
import foto11 from './foto11.jpeg';
import foto12 from './foto12.jpeg';
import foto13 from './foto13.jpeg';
import foto14 from './foto14.jpeg';
import foto15 from './foto15.jpeg';
import foto16 from './foto16.jpeg';
import foto17 from './foto17.jpeg';
import foto18 from './foto18.jpeg';
import foto19 from './foto19.jpeg';
import foto20 from './foto20.jpeg';
import foto21 from './foto21.jpeg';
import foto22 from './foto22.jpeg';
import foto23 from './foto23.jpeg';
import foto24 from './foto24.jpeg';
import foto25 from './foto25.jpeg';
import foto26 from './foto26.jpeg';
import foto27 from './foto27.jpeg';
import foto28 from './foto28.jpeg';
import foto29 from './foto29.jpeg';
import foto30 from './foto30.jpeg';
import foto31 from './foto31.jpeg';
import foto32 from './foto32.jpeg';
import foto33 from './foto33.jpeg';
import foto34 from './foto34.jpeg';
import foto35 from './foto35.jpeg';
import foto37 from './foto37.jpeg';
import foto38 from './foto38.jpeg';
import foto39 from './foto39.jpeg';
import foto40 from './foto40.jpeg';
import foto41 from './foto41.jpeg';

const images = [
  imagem1, imagem2, imagem3, imagem4,
  foto1, foto2, foto3, foto4, foto5,
  foto6, foto8, foto10, foto11, foto12,
  foto13, foto14, foto15, foto16, foto17,
  foto18, foto19, foto20, foto21, foto22,
  foto23, foto24, foto25, foto26, foto27,
  foto28, foto29, foto30, foto31, foto32,
  foto33, foto34, foto35,  foto37,
  foto38, foto39, foto40, foto41
];

export default function Carrocel() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 720,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };

  return (
    <Container>
      <Title>Conheça mais o Professor Edson.</Title>
      <Slider {...settings}>
        {images.map((img, index) => (
          <SlideItem key={index}>
            <img src={img} alt={`Slide ${index + 1}`} />
          </SlideItem>
        ))}
      </Slider>
    </Container>
  );
}