import React, { useState, useEffect, useRef } from 'react';
import { StyledApresentacao, StyledTopo } from "./styleTopo";
import { data } from "../data";
import vidiocapa from './vidiocapa.mp4';

export default function Topo() {
    const [topoVisible, setTopoVisible] = useState(false);
    const [apresentacaoVisible, setApresentacaoVisible] = useState(false);
    const videoRef = useRef(null);

    useEffect(() => {
        const topoTimer = setTimeout(() => {
            setTopoVisible(true);
        }, 200);

        const apresentacaoTimer = setTimeout(() => {
            setApresentacaoVisible(true);
        }, 200);

        return () => {
            clearTimeout(topoTimer);
            clearTimeout(apresentacaoTimer);
        };
    }, []);

    const handleScrollToServicos = () => {
        const servicosElement = document.getElementById('servicos');
        servicosElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    };

    const handleLoadedData = () => {
        if (videoRef.current) {
            videoRef.current.play(); // Inicia o vídeo assim que estiver carregado
        }
    };

    return (
        <>
            <StyledTopo className={topoVisible ? 'visible' : ''}>
                {/* Código do topo */}
            </StyledTopo>
            <StyledApresentacao className={apresentacaoVisible ? 'visible' : ''}>
                <div className="texto-container">
                    <button onClick={() => videoRef.current.muted = !videoRef.current.muted}>
                        {videoRef.current && videoRef.current.muted ? 'Ativar Som' : 'Silenciar'}
                    </button> 
                    <button onClick={handleScrollToServicos}>{data.topoMenu.botao}</button>
                </div>
                <video
                    ref={videoRef}
                    src={vidiocapa}
                    autoPlay
                    loop
                    muted // Mantenha o vídeo mudo inicialmente para autoplay
                    playsInline
                    preload="auto"
                    onLoadedData={handleLoadedData}
                    style={{ width: 'auto', height: '100vh', position: 'relative', objectFit: 'cover' }} 
                />
            </StyledApresentacao>
        </>
    );
}
